import React from "react";
import Login from "./components/login/Login";
import Logout from "./components/logout/Logout";
import Error from "./components/error/Error";
import Info from "./components/info/Info";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TwoFactorAuth from "./components/twofactor/TwoFactorAuth";
import ForgotPasswordPage from "./components/forgot/ForgotPasswordPage";
import ResetPasswordPage from "./components/reset/ResetPasswordPage";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
} from "@apollo/client";

import { HTTP_LINK } from "./ApolloLinks";
import { CookiesProvider } from "react-cookie";

function App() {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([HTTP_LINK]),
  });

  return (
    <ApolloProvider client={client}>
      <CookiesProvider>
        <Router>
          <Routes>
            <Route path="error" element={<Error />} />
            <Route path="info" element={<Info />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="forgot" element={<ForgotPasswordPage />} />
            <Route
              path="/setpassword/:id/:token"
              element={<ResetPasswordPage />}
            />
            <Route path="verify" element={<TwoFactorAuth />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </CookiesProvider>
    </ApolloProvider>
  );
}

export default App;
