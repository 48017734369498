import { useState } from "react";
import "./resetpasswordpage.css";
import { Button } from "react-bootstrap";
import Footer from "../footer/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../generated/graphql";
import { Password } from "../common/Password";

function ResetPasswordPage() {
  let { id, token } = useParams();
  const [searchParams] = useSearchParams();

  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [complete, setComplete] = useState<boolean>(false);
  const redirectUri = searchParams.get("redirect_uri");
  const clientId = searchParams.get("client_id");

  const isValidPassword = (password: String): Boolean => {
    const regex = /(?=.*\d)(?=.*[A-Z]).{8,32}/;
    return !password.match(regex);
  };

  const [resetPassword] = useResetPasswordMutation({
    onCompleted: (res) => {
      setComplete(true);
      if (res?.resetPassword?.redirectUri)
        setTimeout(() => {
          window.location.href = res?.resetPassword?.redirectUri!;
        }, 5000);
    },
    onError: (e) => {
      setError(`An error occurred trying to reset password`);
    },
  });

  const validatePassword = () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return false;
    }

    if (isValidPassword(password)) {
      setError(
        "Password must be at least 8 characters long, containing 1 or more digit(s) and upper case letter(s)."
      );
      return false;
    }

    return true;
  };

  let component = (
    <div className="form-group">
      <div className="error-status">{error}</div>
      <Password
        placeholder="Password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <p>
        <em>
          (at least 8 characters including one capital and numeric character)
        </em>
      </p>
      <br />
      <Password
        placeholder="Confirm Password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <br />
      <div className="login-buttons">
        <Button
          className="primary-button"
          onClick={() => {
            if (validatePassword()) {
              resetPassword({
                variables: {
                  input: {
                    id: id!,
                    token: token!,
                    newPassword: password,
                    redirectUri,
                    clientId,
                  },
                },
              });
            }
          }}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );

  if (complete) {
    let redirectText = null;
    if (redirectUri) {
      redirectText = (
        <p style={{ marginTop: "1rem", fontStyle: "italic", fontSize: "1rem" }}>
          You're being redirected...
        </p>
      );
    }

    component = (
      <h5 style={{ textAlign: "center", fontWeight: "normal" }}>
        <em>Your password has been changed successfully.</em>
        {redirectText}
      </h5>
    );
  }

  return (
    <div className="login">
      <div className="center-h">
        <div className="center-v">
          <h3 className="login-title">Reset Your Password</h3>
          {component}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPasswordPage;
