import { createHttpLink } from "@apollo/client";

export const HTTP_LINK = createHttpLink({
  uri: `/graphql`,
});

// export const USER_AUTH_LINK = setContext((_, { headers }) => {
//   const token = getToken(true);
//   return {
//     headers: {
//       ...headers,
//       Authorization: `Bearer ${token}`,
//     },
//   };
// });
