import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";

function Info() {
  const [searchParams] = useSearchParams();
  const description = searchParams.get("info_description");

  return (
    <div className="login">
      <div className="center-h">
        <div className="center-v">
          <div className="bsq-logo"></div>

          <div className="text-center mt-5 d-flex align-items-center fw-bold justify-content-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="m-2 text-info"
              style={{ fontSize: "2rem", color: "#5bc0de" }}
            />
            {"  "}
            {description || `Brightsquid Authorization Server`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
