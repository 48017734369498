import { useState } from "react";

export const Password = ({
  name,
  value,
  onChange,
  placeholder,
}: {
  name: string;
  value: string;
  onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-group">
      <input
        required
        type={showPassword ? "text" : "password"}
        className="form-control"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div className="input-group-addon">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowPassword(!showPassword);
          }}
        >
          <i
            className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
            aria-hidden="true"
          ></i>
        </a>
      </div>
    </div>
  );
};
