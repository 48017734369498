import React, { useEffect, useState } from "react";
import "./twofactorauth.css";
import { useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Footer from "../footer/Footer";
import OtpInput from "react-otp-input";

function TwoFactorAuth() {
  const [seconds, setSeconds] = useState(30);
  const [mfaLocked, setMfaLocked] = useState(false);

  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);

  const [searchParams] = useSearchParams();

  const client_id = searchParams.get("client_id")!;
  const scope = searchParams.get("scope")!;
  const state = searchParams.get("state")!;
  const phoneNumber = searchParams.get("m_number")!;
  const redirect_uri = searchParams.get("redirect_uri")!;

  const hasErrorDescription = searchParams.has("error_description");

  let errorStr = null;

  if (hasErrorDescription) {
    errorStr = searchParams.get("error_description");
  }

  useEffect(() => {
    let timer: any;
    if (seconds) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [seconds]);

  return (
    <div className="login">
      <div className="center-h">
        <div className="center-v">
          <form method="post" action="/oauth2/authorize">
            <div className="bsq-logo"></div>

            <div className="otp-verification">
              <div
                className="title"
                style={{ fontSize: "18px", marginTop: 20, marginBottom: 20 }}
              >
                <h4 style={{ marginBottom: 16 }}>Verification</h4>
                <h5 className="otp-helper">
                  Enter 6 digit verification token sent to {phoneNumber}
                </h5>
              </div>
              <OtpInput
                containerStyle={{ placeContent: "center" }}
                value={otp}
                onChange={(v: string) => {
                  setOtp(v);
                }}
                shouldAutoFocus
                isDisabled={mfaLocked}
                numInputs={6}
                separator={<strong>-</strong>}
                isInputNum={true}
                inputStyle="otp-inputstyle"
              />
              <input type="hidden" name="client_id" value={client_id} />
              <input type="hidden" name="scope" value={scope} />
              <input type="hidden" name="state" value={state} />
              <input type="hidden" name="security_code" value={otp} />
              <input type="hidden" name="redirect_uri" value={redirect_uri} />
              <input
                type="hidden"
                name="resend_security_code"
                value={`${resend}`}
              />
              <div className="login-buttons">
                <button
                  type="submit"
                  className="primary-button"
                  disabled={mfaLocked || otp?.length < 6}
                >
                  Submit Code
                </button>
                <div className="error-status">{errorStr}</div>
                {!mfaLocked && (
                  <Button
                    type="submit"
                    disabled={seconds > 0 || mfaLocked}
                    variant="link"
                    onClick={() => {
                      setOtp("");
                      setResend(true);
                    }}
                  >
                    Resend verification token
                    {seconds > 0 && ` (in ${seconds} seconds)`}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TwoFactorAuth;
