import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

export interface LogoutProps {
  clientIdConfigurations: ClientIdConfiguration[];
  clientId: string;
  postLogoutRedirectUrl: string;
}

export interface ClientIdConfiguration {
  registeredClientId: string;
  appName: string;
  logoutUrl: string;
}

function Logout() {
  const [searchParams] = useSearchParams();
  const postLogoutRedirectUri = searchParams.get("post_logout_redirect_uri");
  const [loadedApps, setLoadedApps] = useState<string[]>([]);

  const [cookie] = useCookies(["logout"]);
  const logout = cookie.logout as LogoutProps;

  useEffect(() => {
    let timer1 = setTimeout(() => {
      if (postLogoutRedirectUri) {
        window.location.href = postLogoutRedirectUri;
      }
    }, 5 * 1000);

    return () => {
      clearTimeout(timer1);
    };
  }, [postLogoutRedirectUri]);

  if (!logout?.clientIdConfigurations?.length && postLogoutRedirectUri) {
    window.location.href = postLogoutRedirectUri;
  }

  if (
    loadedApps.length === logout.clientIdConfigurations.length &&
    postLogoutRedirectUri
  ) {
    window.location.assign(postLogoutRedirectUri!);
  }

  return (
    <div className="login">
      <div className="center-h">
        <div className="center-v">
          <div style={{ display: "flex", alignItems: "center", fontSize: 24 }}>
            <FontAwesomeIcon icon={faSpinner} spin />
            <span style={{ marginLeft: 24 }}>Logging out</span>
          </div>
        </div>
      </div>
      {logout.clientIdConfigurations.map((c) => (
        <iframe
          key={c.registeredClientId}
          src={c.logoutUrl}
          style={{ display: "none" }}
          onLoad={() => {
            setLoadedApps((apps) => [...apps, c.registeredClientId]);
          }}
        ></iframe>
      ))}
    </div>
  );
}

export default Logout;
