import { useState } from "react";
import { Button } from "react-bootstrap";
import { useForgotPasswordMutation } from "../../generated/graphql";
import Footer from "../footer/Footer";
import "./forgotpasswordpage.css";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<String>("");
  const [complete, setComplete] = useState<boolean>(false);

  const [forgotPassword] = useForgotPasswordMutation({
    onCompleted: () => {
      setComplete(true);
    },
    onError: () => {
      setError(`An error occurred trying to reset password`);
    },
  });

  const validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const resetPassword = () => {
    const str = email.trim();
    if (validateEmail(str)) {
      forgotPassword({
        variables: {
          input: {
            email: str,
          },
        },
      });
    } else {
      setError("Please enter a valid email address.");
    }
  };

  let component = (
    <>
      <div className="form-group">
        <label
          style={{
            fontSize: "16px",
            color: "#000",
          }}
        >
          Enter your email address associated with your Brightsquid account
        </label>
        <input
          required
          name="email"
          type="text"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="error-status">{error}</div>
      </div>
      <div className="login-buttons">
        <Button
          type="submit"
          className="primary-button"
          onClick={resetPassword}
        >
          Reset Password
        </Button>
        <a className="btn btn-default btn-link password-btn" href="/login">
          Cancel
        </a>
      </div>
    </>
  );

  if (complete) {
    component = (
      <>
        <h5 style={{ fontWeight: "normal" }}>
          If an account matching this email exists, a password reset link has
          been sent to {email}.
        </h5>
        <br />
        <h5 style={{ fontWeight: "normal" }}>
          If you don't see the email in your inbox, please check your spam
          folder.
        </h5>
      </>
    );
  }

  return (
    <div className="login rp">
      <div className="center-h">
        <div className="center-v">
          <div className="bsq-logo"></div>
          <h3 className="login-title">Reset Password</h3>
          {component}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPasswordPage;
