import "./footer.css";
import ComodoLogo from "../../img/comodo_secure.png";
import HipaaLogo from "../../img/hipaa-color.png";
import SecurityMetricsLogo from "../../img/security-metrics.png";
import SecureMailLogo from "../../img/secure-mail-compatible.png";
import Zendesk from "./Zendesk";

function Footer() {
  return (
    <div className="login-footer">
      <a target="_blank" rel="noopener noreferrer" href="/compliance">
        <img src={ComodoLogo} className="comodo-logo" alt="Comodo Logo" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href="/compliance">
        <img src={HipaaLogo} alt="Hipaa Logo" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href="/compliance">
        <img src={SecurityMetricsLogo} alt="Secure Mail Logo" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href="/compliance">
        <img
          src={SecureMailLogo}
          className="security-metrics-logo"
          alt="Security Metrics Logo"
        />
      </a>
      <div className="copyright-text">Copyright&nbsp;2022&nbsp;Brightsquid</div>
      <Zendesk />
    </div>
  );
}

export default Footer;
