import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

function Error() {
  const [searchParams] = useSearchParams();
  const description = searchParams.get("error_description");

  return (
    <div className="login">
      <div className="center-h">
        <div className="center-v">
          <div className="bsq-logo"></div>
          <div className="text-center mt-5 d-flex align-items-center fw-bold justify-content-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="m-2 text-danger"
              style={{ fontSize: "2rem", color: "#c500138a" }}
            />
            {"  "}
            {description ||
              `Something went wrong. Please go to login page and start over.`}
          </div>
          <Button className="primary-button mt-5" href="/login">
            Sign in with your Brightsquid Account
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Error;
