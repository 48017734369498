import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useSearchParams } from "react-router-dom";
import { Password } from "../common/Password";
import Footer from "../footer/Footer";
import "./login.css";

function Login() {
  const [searchParams] = useSearchParams();

  const [username, setUsername] = useState(searchParams.get("username") || "");
  const [password, setPassword] = useState("");
  const app = searchParams.get("app");
  const [bannerText, setBannerText] = useState("");

  useEffect(() => {
    if (process.env.REACT_APP_BANNER_URL) {
      fetch(process.env.REACT_APP_BANNER_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/text",
        },
      }).then((response) => {
        response.text().then((data) => {
          setBannerText(data);
        });
      });
    }
  }, []);

  const isEmdClient = app === "emd";
  const hasError = searchParams.has("error");

  let errorStr = null;

  if (hasError) {
    if (searchParams.has("error_description")) {
      errorStr = searchParams.get("error_description");
    } else {
      errorStr = "Incorrect username or password";
    }
  }

  return (
    <div className="login">
      {bannerText ? (
        <div dangerouslySetInnerHTML={{ __html: bannerText }} />
      ) : null}
      <div className="center-h">
        <div className="center-v">
          {isEmdClient ? (
            <div>
              <img
                src="https://bsq-prod-public.s3.ca-central-1.amazonaws.com/eMD_Logo.png"
                className="app-logo"
              />
              <h6 className="title center-h">
                Sign in using your Brightsquid account
              </h6>
            </div>
          ) : (
            <div className="bsq-logo"></div>
          )}
          <form method="post" action="/login">
            {isEmdClient ? null : (
              <h3 className="title">
                We are one connected healthcare community
              </h3>
            )}

            <div className="form-group">
              <label htmlFor="username">Email</label>
              <input
                required
                name="username"
                type="text"
                className="form-control"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group password-group">
              <label htmlFor="password">Password</label>
              <Password
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button variant="link" className="forgot-link" href="/forgot">
              Forgot my password
            </Button>

            <div className="login-buttons">
              <Button
                disabled={!username || !password}
                type="submit"
                className="primary-button"
              >
                Login
              </Button>
              {errorStr && (
                <div className="error-status mb-3 mt-0">{errorStr}</div>
              )}
              <div className="help-link tnc">
                By clicking Login, you agree to our{" "}
                <a
                  href="https://brightsquid.com/pages/brightsquid-application-terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"Terms of Use"}
                </a>
                {" and "}
                <a
                  href="https://brightsquid.com/pages/brightsquid-application-privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"Privacy Policy"}
                </a>
              </div>

              <Button
                variant="link"
                className="help-link"
                href={
                  isEmdClient
                    ? "https://brightsquid.com/products/emd-consults"
                    : "https://support.brightsquid.com"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us for help
              </Button>
            </div>
            <input type="hidden" name="app" value={app || ""} />
          </form>
        </div>
      </div>
      {!isEmdClient && <Footer />}
    </div>
  );
}

export default Login;
